<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start mb-5">
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek"
              @click="Filter('thisWeek')"
            >
              Minggu Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth"
              @click="Filter('thisMonth')"
            >
              Bulan Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth"
              @click="Filter('lastMonth')"
            >
              Bulan Kemarin
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisYear"
              @click="Filter('thisYear')"
            >
              Tahun Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.period"
              @click="Filter('period')"
            >
              Periode
            </button>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-xl-6" v-if="tooltipPeriod == 'period'">
        <span class="d-none d-sm-block">
          <b-input-group>
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-button squared variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <template #append>
              <b-button squared @click="filterByPeriode" variant="success"
                >Cari</b-button
              >
              <b-button squared @click="resetFilterPeriode" variant="danger"
                >Reset</b-button
              >
            </template>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
                <template #prepend>
                  <b-button squared @click="filterByPeriode" variant="success"
                    >Cari</b-button
                  >
                  <b-button squared @click="resetFilterPeriode" variant="danger"
                    >Reset</b-button
                  >
                </template>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>

      <div class="col-md-9">
        <apexchart
          class="card-rounded-bottom"
          :options="chartOptions"
          :series="series"
          type="bar"
          width="100%"
          height="400"
          v-if="chartLoad"
        ></apexchart>
      </div>

      <div class="col-md-3">
        <div class="row">
          <div class="col-md-12 text-center mb-4">
            <div class="card">
              <div class="card-body">
                <span class="d-block" style="font-size: 14px"
                  >Total Kunjungan / Perjanjian</span
                >
                <span
                  class="d-block font-weight-bold"
                  style="font-size: 40px"
                  >{{ totalAppointment }}</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-12 text-center mb-4">
            <div class="card">
              <div class="card-body">
                <span class="d-block" style="font-size: 14px"
                  >Total Pasien Kunjungan</span
                >
                <span
                  class="d-block font-weight-bold"
                  style="font-size: 40px"
                  >{{ totalVisit }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      
      series: [
        {
          name: "Kunjungan",
          data: [],
        },
        {
          name: "Pasien",
          data: [],
        },
      ],
      sum: "",
      arr: [],
      chartLoad: false,
      sumLoad: false,
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
        period: "btn-outline-purple",
      },
      totalAppointment: 0,
      totalVisit: 0,

      tooltipPeriod: "thisWeek",

      // chart
      // series: [],
      chartOptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["#a12260", "#d7a85b"],
        },
        xaxis: {
          categories: [],
        },
        // yaxis: {
        //   title: {
        //     text: "$ (thousands)",
        //   },
        // },
        fill: {
          opacity: 1,
        },
        colors: ["#a12260", "#d7a85b"],
        // colors: ["#F44336"],
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
          text: "Statistik Kunjungan & Perjanjian",
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bolder",
            // fontFamily:  undefined,
            // color:  '#263238'
          },
        },
      },
    };
  },
  methods: {
    Filter(tool) {
      this.tooltipPeriod = tool;
      
      console.log(tool);

      if (tool == "thisWeek") {
        this.period.thisWeek = "btn-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
        this.period.period = "btn-outline-purple";
        this.getData();
        this.chartLoad = false;
      } else if (tool == "thisMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
        this.period.period = "btn-outline-purple";
        this.getData();
        this.chartLoad = false;
      } else if (tool == "lastMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-purple";
        this.period.thisYear = "btn-outline-purple";
        this.period.period = "btn-outline-purple";
        this.getData();
        this.chartLoad = false;
      } else if (tool == "thisYear") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-purple";
        this.period.period = "btn-outline-purple";
        this.getData();
        this.chartLoad = false;
      }else if (tool == "period") {
        
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
        this.period.period = "btn-purple";

        // this.periodFilter()
      }

      

      mapGetters(["layoutConfig"]);
    },
    filterByPeriode() {
      this.tooltipPeriod = "period";
      this.chartLoad = false;
      this.getData();
      mapGetters(["layoutConfig"]);
    },
    resetFilterPeriode() {
      // this.chartLoad = false;
      this.tooltipPeriod = "thisWeek";
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.Filter('thisWeek')
      this.getData();
      mapGetters(["layoutConfig"]);
    },

    month(month) {
      let monthName;
      if (month == 1) {
        monthName = "Jan";
        // monthName = "Januari"
      } else if (month == 2) {
        monthName = "Feb";
        // monthName = "Februari"
      } else if (month == 3) {
        monthName = "Mar";
        // monthName = "Maret"
      } else if (month == 4) {
        monthName = "Apr";
        // monthName = "April"
      } else if (month == 5) {
        monthName = "Mei";
        // monthName = "Mei"
      } else if (month == 6) {
        monthName = "Jun";
        // monthName = "Juni"
      } else if (month == 7) {
        monthName = "Jul";
        // monthName = "Juli"
      } else if (month == 8) {
        monthName = "Agu";
        // monthName = "Agustus"
      } else if (month == 9) {
        monthName = "Sep";
        // monthName = "September"
      } else if (month == 10) {
        monthName = "Okt";
        // monthName = "Oktober"
      } else if (month == 11) {
        monthName = "Nov";
        // monthName = "November"
      } else if (month == 12) {
        monthName = "Des";
        // monthName = "Desember"
      }

      return monthName;
    },

    async getData(tool) {

      this.$root.$emit("chartGenderFilter",this.tooltipPeriod, this.filter.start_date, this.filter.end_date)

      window.arrData = [];
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get(
        "graphic-appointment",
        `${filterParams}`
      );
      let responseVisit = await module.get(
        "graphic-visit",
        `${filterParams}`
      );

      let patient = [];
      let patientVisit = [];
      let typePeriod = "Tanggal";

      this.totalAppointment = parseInt(response.total).toLocaleString("id-ID");
      this.totalVisit = parseInt(responseVisit.total).toLocaleString("id-ID");

      if (
        this.tooltipPeriod != "thisYear" &&
        this.tooltipPeriod != "thisWeek" &&
        this.tooltipPeriod != "period"
      ) {
        let b = 0;
        let counter = 1;
        for (let a = 0; a <= response.days; a++) {
          if (typeof response.items[b] !== "undefined") {
            let monthName = this.month(response.items[b].month);

            if (response.items[b].day == counter) {
              patient.push({
                ref_name: parseInt(response.items[b].day) + "-" + monthName,
                price: response.items[b].amount,
              });
              b++;
            } else {
              patient.push({
                ref_name: parseInt(counter) + "-" + monthName,
                price: 0,
              });
            }
          } else {
            let month;
            if (this.tooltipPeriod == "lastMonth") {
              month = moment().subtract(1, "month").format("MM");
            } else if (this.tooltipPeriod == "thisMonth") {
              month = moment().format("MM");
            }

            month = this.month(parseInt(month));
            patient.push({
              ref_name: parseInt(counter) + "-" + month,
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      } else if (this.tooltipPeriod == "thisYear") {
        let b = 1;
        for (let a = 0; a < 12; a++) {
          if (typeof response.items[a] !== "undefined") {
            let monthName;
            if (response.items[a].month == 1) {
              monthName = "Januari";
            } else if (response.items[a].month == 2) {
              monthName = "Februari";
            } else if (response.items[a].month == 3) {
              monthName = "Maret";
            } else if (response.items[a].month == 4) {
              monthName = "April";
            } else if (response.items[a].month == 5) {
              monthName = "Mei";
            } else if (response.items[a].month == 6) {
              monthName = "Juni";
            } else if (response.items[a].month == 7) {
              monthName = "Juli";
            } else if (response.items[a].month == 8) {
              monthName = "Agustus";
            } else if (response.items[a].month == 9) {
              monthName = "September";
            } else if (response.items[a].month == 10) {
              monthName = "Oktober";
            } else if (response.items[a].month == 11) {
              monthName = "November";
            } else if (response.items[a].month == 12) {
              monthName = "Desember";
            }

            patient.push({
              ref_name: monthName,
              price: response.items[a].amount,
            });
          } else {
            let monthName;
            if (b == 1) {
              monthName = "Januari";
            } else if (b == 2) {
              monthName = "Februari";
            } else if (b == 3) {
              monthName = "Maret";
            } else if (b == 4) {
              monthName = "April";
            } else if (b == 5) {
              monthName = "Mei";
            } else if (b == 6) {
              monthName = "Juni";
            } else if (b == 7) {
              monthName = "Juli";
            } else if (b == 8) {
              monthName = "Agustus";
            } else if (b == 9) {
              monthName = "September";
            } else if (b == 10) {
              monthName = "Oktober";
            } else if (b == 11) {
              monthName = "November";
            } else if (b == 12) {
              monthName = "Desember";
            }

            patient.push({
              ref_name: monthName,
              price: 0,
            });
          }

          b++;
        }

        typePeriod = "Bulan";
      } else if (this.tooltipPeriod == "thisWeek") {
        let b = 0;
        let counter = response.start;

        for (let a = 0; a <= response.days; a++) {
          let month;
          let startMonth = moment().startOf("week").format("MM");
          let monthName = this.month(parseInt(startMonth));

          if (typeof response.items[b] !== "undefined") {
            monthName = this.month(parseInt(response.items[b].month));

            patient.push({
              ref_name: parseInt(response.items[b].day) + "-" + monthName,
              price: response.items[b].amount,
            });
            b++;
          } else {
            if (counter < response.start) {
              month = moment().add(1, "months").format("MM");
              monthName = this.month(parseInt(month));
            }
            patient.push({
              ref_name: parseInt(counter) + "-" + monthName,
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      }else if (this.tooltipPeriod == "period") {
        let b = 0;
        let counter = response.start;

        for (let a = 0; a <= response.days; a++) {
          let month;
          let startMonth = moment().startOf("week").format("MM");
          let monthName = this.month(parseInt(startMonth));

          if (typeof response.items[b] !== "undefined") {
            monthName = this.month(parseInt(response.items[b].month));

            patient.push({
              ref_name: parseInt(response.items[b].day) + "-" + monthName +"-"+response.items[b].year,
              price: response.items[b].amount,
            });
            b++;
          } else {
            if (counter < response.start) {
              month = moment().add(1, "months").format("MM");
              monthName = this.month(parseInt(month));
            }
            patient.push({
              ref_name: parseInt(counter) + "-" + monthName,
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      }

      // patient visits amount
      if (
        this.tooltipPeriod != "thisYear" &&
        this.tooltipPeriod != "thisWeek" &&
        this.tooltipPeriod != "period"
      ) {
        let b1 = 0;
        let counter1 = 1;
        for (let a = 0; a <= responseVisit.days; a++) {
          if (typeof responseVisit.items[b1] !== "undefined") {
            let monthName1 = this.month(responseVisit.items[b1].month);

            if (responseVisit.items[b1].day == counter1) {
              patientVisit.push({
                ref_name: parseInt(responseVisit.items[b1].day) + "-" + monthName1,
                price: responseVisit.items[b1].amount,
              });
              b1++;
            } else {
              patientVisit.push({
                ref_name: parseInt(counter1) + "-" + monthName1,
                price: 0,
              });
            }
          } else {
            let month1;
            if (this.tooltipPeriod == "lastMonth") {
              month1 = moment().subtract(1, "month").format("MM");
            } else if (this.tooltipPeriod == "thisMonth") {
              month1 = moment().format("MM");
            }

            month1 = this.month(parseInt(month1));
            patientVisit.push({
              ref_name: parseInt(counter1) + "-" + month1,
              price: 0,
            });
          }
          counter1++;
        }

        typePeriod = "Tanggal";
      } else if (this.tooltipPeriod == "thisYear") {
        let b1 = 1;
        for (let a = 0; a < 12; a++) {
          if (typeof responseVisit.items[a] !== "undefined") {
            let monthName1;
            if (responseVisit.items[a].month == 1) {
              monthName1 = "Januari";
            } else if (responseVisit.items[a].month == 2) {
              monthName1 = "Februari";
            } else if (responseVisit.items[a].month == 3) {
              monthName1 = "Maret";
            } else if (responseVisit.items[a].month == 4) {
              monthName1 = "April";
            } else if (responseVisit.items[a].month == 5) {
              monthName1 = "Mei";
            } else if (responseVisit.items[a].month == 6) {
              monthName1 = "Juni";
            } else if (responseVisit.items[a].month == 7) {
              monthName1 = "Juli";
            } else if (responseVisit.items[a].month == 8) {
              monthName1 = "Agustus";
            } else if (responseVisit.items[a].month == 9) {
              monthName1 = "September";
            } else if (responseVisit.items[a].month == 10) {
              monthName1 = "Oktober";
            } else if (responseVisit.items[a].month == 11) {
              monthName1 = "November";
            } else if (responseVisit.items[a].month == 12) {
              monthName1 = "Desember";
            }

            patientVisit.push({
              ref_name: monthName1,
              price: responseVisit.items[a].amount,
            });
          } else {
            let monthName1;
            if (b1 == 1) {
              monthName1 = "Januari";
            } else if (b1 == 2) {
              monthName1 = "Februari";
            } else if (b1 == 3) {
              monthName1 = "Maret";
            } else if (b1 == 4) {
              monthName1 = "April";
            } else if (b1 == 5) {
              monthName1 = "Mei";
            } else if (b1 == 6) {
              monthName1 = "Juni";
            } else if (b1 == 7) {
              monthName1 = "Juli";
            } else if (b1 == 8) {
              monthName1 = "Agustus";
            } else if (b1 == 9) {
              monthName1 = "September";
            } else if (b1 == 10) {
              monthName1 = "Oktober";
            } else if (b1 == 11) {
              monthName1 = "November";
            } else if (b1 == 12) {
              monthName1 = "Desember";
            }

            patientVisit.push({
              ref_name: monthName1,
              price: 0,
            });
          }

          b1++;
        }

        typePeriod = "Bulan";
      } else if (this.tooltipPeriod == "thisWeek") {
        let b1 = 0;
        let counter1 = responseVisit.start;

        for (let a = 0; a <= responseVisit.days; a++) {
          let month1;
          let startMonth1 = moment().startOf("week").format("MM");
          let monthName1 = this.month(parseInt(startMonth1));

          if (typeof responseVisit.items[b1] !== "undefined") {
            monthName1 = this.month(parseInt(responseVisit.items[b1].month));

            patientVisit.push({
              ref_name: parseInt(responseVisit.items[b1].day) + "-" + monthName1,
              price: responseVisit.items[b1].amount,
            });
            b1++;
          } else {
            if (counter1 < responseVisit.start) {
              month1 = moment().add(1, "months").format("MM");
              monthName1 = this.month(parseInt(month1));
            }
            patientVisit.push({
              ref_name: parseInt(counter1) + "-" + monthName1,
              price: 0,
            });
          }
          counter1++;
        }

        typePeriod = "Tanggal";
      }else if (this.tooltipPeriod == "period") {
        let b1 = 0;
        let counter1 = responseVisit.start;

        for (let a = 0; a <= responseVisit.days; a++) {
          let month1;
          let startMonth1 = moment().startOf("week").format("MM");
          let monthName1 = this.month(parseInt(startMonth1));

          if (typeof responseVisit.items[b1] !== "undefined") {
            monthName1 = this.month(parseInt(responseVisit.items[b1].month));

            patientVisit.push({
              ref_name: parseInt(responseVisit.items[b1].day) + "-" + monthName1 +"-"+responseVisit.items[b1].year,
              price: responseVisit.items[b1].amount,
            });
            b1++;
          } else {
            if (counter1 < responseVisit.start) {
              month1 = moment().add(1, "months").format("MM");
              monthName1 = this.month(parseInt(month1));
            }
            patientVisit.push({
              ref_name: parseInt(counter1) + "-" + monthName1,
              price: 0,
            });
          }
          counter1++;
        }

        typePeriod = "Tanggal";
      }

      console.log(patient,patientVisit);

      this.series[0].data = patient.map((patient) => parseFloat(patient.price));
      this.series[1].data = patientVisit.map((patientVisit) => parseFloat(patientVisit.price));

      console.log(this.series);
      window.arrData = patient.map((patient) => String(patient.ref_name));
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: window.arrData,
            title: {
              text: "Periode " + typePeriod,
            },
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getData();
    if (this.chartLoad == true) {
    }

    // this.$root.$on('filterChart',(tool)=>{
    //   this.Filter(tool)
    // })
    // reference; kt_stats_widget_12_chart
  },
};
</script>
