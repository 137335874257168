<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Perjanjian</b> Klinik Hayandra</div>
      <b-button
        squared
        variant="success"
        @click="$router.push('/appointment/add')"
        v-if="manipulateBtn == true"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row mb-3">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body p-5">
            <div class="row">
              <div class="col-md-12">
                <b-button
                  v-b-toggle.collapse-1
                  class="w-100 btn-purple"
                  @click="open"
                  >{{ textBtn }}</b-button
                >

                <b-collapse id="collapse-1" class="mt-4">
                  
                  <AppointmentChart></AppointmentChart>
                  <AppointmentChartGender></AppointmentChartGender>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table
              :items="items"
              :itemExcel="itemExcel"
              :itemPopUp="itemPopUp"
              :fields="fields"
              :fieldPopUp="fieldPopUp"
              :perPage="perPage"
              :currentPage="currentPage"
              :totalRows="totalRows"
              :tabFiled="tabFiled"
              @btnDeleteOnClick="btnDeleteOnClick"
              @filter="generalFilter"
              @filterByPatient="generalFilter"
              @appointmentOnStatusChange="pagination"
              @pageOnClick="pageOnClick"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import Table from "@/component/appointments/Table.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getUser } from "@/core/services/jwt.service.js";
import AppointmentChart from "@/component/report-charts/AppointmentChart.vue";
import AppointmentChartGender from "@/component/report-charts/AppointmentGenderChart.vue";

export default {
  components: {
    Card,
    Table,
    AppointmentChart,
    AppointmentChartGender
  },

  data() {
    return {
      // Filter
      filter: {
        appointment_status_id: "",
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "doctor_name_action_type_name",
          label: "Dokter & Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      fieldPopUp: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "doctor_name_action_type_name",
          label: "Dokter & Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
      ],
      // Table Items
      items: [],
      itemExcel: [],
      itemPopUp: [],
      // Other
      tabFiled: 0,
      // access management
      manipulateBtn: false,
      // current user
      currentUser: getUser(),
      //collapse info
      textBtn: "Tampilkan Detail",
      collapseOpen: false,
    };
  },

  methods: {
    pageOnClick(page) {
      console.log(page, "page");
      this.currentPage = page;
      this.pagination();
    },

    open() {
      if (this.collapseOpen == false) {
        this.collapseOpen = true;
        this.textBtn = "Sembunyikan Detail";
      } else {
        this.collapseOpen = false;
        this.textBtn = "Tampilkan Detail";
      }
    },

    async pagination() {
      let route, excelRoute, popUpRoute;
      if (this.currentUser.user_type_id == 1) {
        route = `appointments/get-by-doctor/${this.currentUser.user_id}`;
        excelRoute = `appointment-excel/get-by-doctor/${this.currentUser.user_id}`;
        popUpRoute = `appointment-no-paginations/get-by-doctor/${this.currentUser.user_id}`;
      } else {
        route = "appointments";
        excelRoute = "appointment-excel";
        popUpRoute = "appointment-no-paginations";
      }
      let filterParams = `&appointment_status_id=${this.filter.appointment_status_id}&patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;

      // export
      let responseExcel = await module.get(
        excelRoute,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let responsePopUp = await module.get(
        popUpRoute,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      this.itemExcel = responseExcel;
      this.itemPopUp = responsePopUp;
    },

    async generalFilter(sended) {
      this.filter.appointment_status_id = sended.appointment_status_id;
      this.filter.doctor_id = sended.doctor_id;
      this.filter.patient_name = sended.patient_name;
      this.filter.start_date = sended.start_date;
      this.filter.end_date = sended.end_date;
      this.pagination();
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("appointments/" + id);
      // If Deleted
      if (result) {
        this.$root.$emit("UpdateNewAppointment");
        this.pagination();
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");
        if (access_right[a] == "5002") {
          this.manipulateBtn = true;
        }
      }
    },

    async setActiveTab() {
      if (this.$route.params.id == 1) {
        this.tabFiled = 1;
        this.filter.appointment_status_id = await 1;
        this.pagination();
      } else if (this.$route.params.id == 0) {
        this.pagination();
      }
    },
  },

  mounted() {
    this.setActiveMenu();
    // Breadcrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Perjanjian" },
      { title: "Semua Perjanjian" },
    ]);
    // Table List
    this.setActiveTab();

    this.$root.$on("filterAppointment", (filter) => {
      this.generalFilter(filter);
      console.log("filter appointment");
    });
    this.$root.$on("refreshAppointment", () => {
      console.log("refreshAppointment");
      this.pagination()
    });
  },
};
</script>

<style>
.btn-outline-purple {
  border: 1px solid #551871;
  color: #551871;
}

.btn-outline-purple:hover {
  background-color: #993366 !important;
  color: white;
}

.btn-purple {
  background-color: #993366 !important;
  color: white !important;
}

.btn-purple:hover {
  background-color: #652143 !important;
  color: white !important;
}

.text-notes {
  color: #bfc0c5 !important;
  font-size: 10px;
}

.notes {
  position: absolute;
  bottom: 14px;
  right: 20px;
}

/* .btn-purple:hover{
  background-color: #551871;
  color: white;
} */
</style>
